import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SipOutboundFunctionService } from '../../services/sip-outbound-function.service';
import { CallCenterSharedService } from '../../services/call-center-shared.service';
import { ToastrService } from 'ngx-toastr';
import { SIPdetailsResponse } from '../../models/call-center.models';
import { SubSink } from 'subsink';
import { SipService } from '../../services/sip.service';
import { KeyPadComponent } from '../key-pad/key-pad.component';
import { PhoneNumberPipe } from 'app/shared/pipes/phone-number.pipe';
import { PhoneNumberFormatDirective } from 'app/shared/directives/phone-number-format.directive';
import { CustomerContactAddComponent } from '../customer-contact-add/customer-contact-add.component';


@Component({
  selector: 'outbound-dialer',
  standalone: true,
  imports: [CommonModule, FormsModule,KeyPadComponent, 
            PhoneNumberPipe,PhoneNumberFormatDirective,CustomerContactAddComponent],
  templateUrl: './outbound-dialer.component.html',
  styleUrl: './outbound-dialer.component.scss'
})
export class OutboundDialerComponent {

  // public dialNumber: string = sessionStorage.getItem('last_dial_number') == null? ''
  //                             :sessionStorage.getItem('last_dial_number');

  public dialNumber=this.callcenterShared.lastDialledNumber==null?'':this.callcenterShared.lastDialledNumber;

  @ViewChild('dialInput') dialInput:ElementRef;

  public displayBackBtn:boolean=false;

  public selectSendAsManual: boolean = true;

  public subs: SubSink = new SubSink();

  public send_as: string = "";

  public showKeypad: boolean = true

  @Output() inputChange=new EventEmitter<any>();

  public showAddContact: boolean = false;
  

  constructor(private sipOutboundfunctions:SipOutboundFunctionService,
    public callcenterShared:CallCenterSharedService,
    private toastr:ToastrService,
    private sipService: SipService,
    private phonenumberpipe:PhoneNumberPipe
  )
  {
    if(this.dialNumber?.length > 6)
      {
        this.dialNumber = this.phonenumberpipe.transform(this.dialNumber)
      }
    
  }
 
  ngOnInit(){

    console.log('tempcontactdataindialer',this.callcenterShared.contactSaveTempData)
    if(this.callcenterShared.contactSaveTempData != null){
      this.showAddContact = true;
     // this.callcenterShared.changePanelClosebtnVisible(false)
    }
  }

  ngAfterViewInit(){
    if(this.dialInput){
      this.dialInput.nativeElement.focus();
    }
  }

  onKeyPress(val: string) {
    this.dialInput.nativeElement.focus(); 

    this.displayBackBtn=true;
    this.dialNumber+=val;
    if(this.dialNumber?.length > 6)
    {
        this.dialNumber = this.phonenumberpipe.transform(this.dialNumber);
    }
    setTimeout(()=>{
      this.dialInput.nativeElement.scrollLeft = this.dialInput.nativeElement.scrollWidth;
    },0);
    this.callcenterShared.lastDialledNumber = this.dialNumber
   

  //   let start=this.dialInput.nativeElement.selectionStart;

  //   if (this.dialNumber.length <= 10){

  //     let number1=this.dialNumber.slice(0, start);
  //     let number2=this.dialNumber.slice(start);

  //     number1+=val;

  //       this.dialNumber=number1+number2;
  //   }

  //   this.cdr.detectChanges();
  //   this.searchInContact(); 

  //  // setTimeout(() => {
  //     this.dialInput.nativeElement.setSelectionRange(start+1, start+1);
  //     this.dialInput.nativeElement.focus();
  //   // }, 10); 
    
  }
  keyUp(event){
    if(this.dialNumber.length==0){
      this.displayBackBtn=false;
    }
    else{
      this.displayBackBtn=true;
    }

    //this.cdr.detectChanges();
  }

  removeNumber(){

    let length=this.dialNumber.length;
    
    if(length){

      let start=this.dialInput.nativeElement.selectionStart;

      if(start==-1||start==0){
        start=length-1;
      }
      else{
        start--;
      }


      let number1=this.dialNumber.slice(0, start);
      let number2=this.dialNumber.slice(start+1);

      this.dialNumber=number1+number2;
      this.dialNumber = this.dialNumber.replace(/\D+/g, ''); // Remove all non-digit characters
      if(this.dialNumber?.length > 6)
      {
          this.dialNumber = this.phonenumberpipe.transform(this.dialNumber);
      }
      if(this.dialNumber.length==0){
        this.displayBackBtn=false;
      }

      // this.cdr.detectChanges();

      setTimeout(() => {
        this.dialInput.nativeElement.setSelectionRange(start, start);
        this.dialInput.nativeElement.focus();
       }, 0);

       this.searchInContact();
    }
  }
  searchInContact(){
     
    setTimeout(() => {
     this.inputChange.emit(this.dialNumber);
    }, 10);


 }
  onCall() {
    if(!this.callcenterShared.isNotificationEnabled){
      this.toastr.error(`Apologies, but you cannot make or receive calls because notifications are not enabled in your browser. 
        Please enable them and try again.`, `Notification is not enabled!`,
        {closeButton: true, disableTimeOut: true, tapToDismiss: false});
      return
    }
    
    if(this.callcenterShared.initialCallQOutbound?.length>0)
      {
        return
      }
    if(this.callcenterShared.acceptCallQOutbound?.length > 0)
      {
        const matchingOutbound =  this.callcenterShared.acceptCallQOutbound?.find(x => x.customValues.callTo 
          == this.dialNumber.replace(/\D+/g, ""))
        if(matchingOutbound)
          {
            this.toastr.warning('This number already initiated');
            return
          }
      }

    if(this.callcenterShared.activeCallerSession)
    {
         this.toastr.error('Please hold current outgoing call');
         return
    }

      
    //    if(this.sipInboundFnService.selectedCaller?.state == "Established" 
    //    && this.sipInboundFnService.selectedCaller?.customValues.isCallOnHold == false)
    //    {
    //     this.toastr.error('Please hold current incoming call');
    //     return
    //    }
    //    if(this.sipInboundFnService.selectedCaller?.state == "Initial")
    //    {
    //     this.toastr.error('Please stop current incoming call');
    //     return
    //    }
    // }
    // if(this.sipOutBoundFunctionService.currentCallDetails.active_CallerSession)
    // {
    //   if(this.sipOutBoundFunctionService.currentCallDetails.active_CallerSession?.state == "Established" 
    //   && this.sipOutBoundFunctionService.currentCallDetails.active_CallerSession?.customValues.isCallOnHold == false)
    //   {
    //    this.toastr.error('Please hold current outgoing call');
    //    return
    //   }
    // }
    let userSipDetails: SIPdetailsResponse =this.callcenterShared.sipDetails

    if (this.dialNumber.length >= 10 || this.dialNumber.length >= 1 ) {

      // sessionStorage.setItem('last_dial_number',this.dialNumber)
      this.callcenterShared.lastDialledNumber=this.dialNumber;
      if (this.selectSendAsManual == true && this.send_as !=  
        userSipDetails.send_as &&
        this.send_as != "" && this.send_as != null) {

        const model:any = { send_as: this.send_as }
        this.subs.sink =  this.sipService.updateSendAs(userSipDetails.sip_id, model).subscribe(
          resp => {
            this.sipOutboundfunctions.makeCall('contact', this.send_as, this.dialNumber,userSipDetails.sip_uri)
            userSipDetails.send_as = this.send_as
          },
          error => {
           console.log(error.error)
          })

      }
      else if (this.selectSendAsManual == true  && this.send_as == "" || this.send_as == null) {

        this.sipOutboundfunctions.makeCall('contact',userSipDetails.send_as, this.dialNumber,userSipDetails.sip_uri)

      }
      else if (this.selectSendAsManual == false)  { //Auto is true

        let sip_sendas_no =this.callcenterShared.sipDetails.send_as
        let callee_no = this.dialNumber.length > 10? this.dialNumber.slice(this.dialNumber.length - 10) 
                        : this.dialNumber

        let caller_areacode =this.callcenterShared.sipDetails.send_as.substring(0, 3)
        let callee_areacode = callee_no.substring(0, 3)

        if (caller_areacode != callee_areacode) {
          this.subs.sink = this.sipOutboundfunctions.updateSendAsNumberWithAreaCd(callee_areacode).subscribe(result => {

            if (result?.status == true) {
              this.sipOutboundfunctions.makeCall('contact',result.new_number, this.dialNumber,userSipDetails.sip_uri)
              userSipDetails.send_as = result.new_number
            }
          })
        }
        else {
          this.sipOutboundfunctions.makeCall('contact',sip_sendas_no, this.dialNumber,userSipDetails.sip_uri)
        }

       
      }
      else
      {
        this.sipOutboundfunctions.makeCall('contact',userSipDetails.send_as, this.dialNumber,userSipDetails.sip_uri)
      }

  
    }
  }
  onDiailNumberChange()
  {
    this.callcenterShared.lastDialledNumber = this.dialNumber
    this.searchInContact()
  }


  delNumber()
  {
    this.dialNumber = ""
    this.searchInContact()
  }
  onCloseContact()
  {
    this.showAddContact = false
    this.callcenterShared.changePanelClosebtnVisible(false)
  }

  toggleAddContact()
  {
    this.showAddContact = !this.showAddContact
    this.callcenterShared.changePanelClosebtnVisible(this.showAddContact)
  }

}
