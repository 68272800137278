<div class="select-wrapper">
    <ng-select
    placeholder="Select Company" 
    [items]="options | async"
    bindLabel="name"
    bindValue="id"
    [typeahead]="textInput"
    [(ngModel)]="selected"
    [minTermLength]="2"
    [trackByFn]="trackByFn"
    typeToSearchText="Please enter 2 or more characters"
    [readonly]="readonly"
    (open)="selectOpen()" 
    [closeOnSelect]="true" 
    (search)="onSearch($event.term)"
    (change)="onValueChanged($event)"
    (scrollToEnd)="loadMoreItems()" 
    [virtualScroll]="true" 
    (clear)="onClear()"
     appendTo="body" 
     [loading]="loading">

    <ng-template ng-option-tmp let-item="item">
        <div class="custom-option">
            <div class="column width-40-per" style="padding-right: 3px;" *ngIf="item.id !== null; else loadingTemplate">
                {{item.name?item.name:'Unknown'}}
            </div>       
        </div>
    </ng-template>
    <ng-template #loadingTemplate>
        <div class="skeleton-loader-container">
            <ngx-skeleton-loader
            count="1"
            appearance="line"
            style="width: 100%; height: 20px;">
          </ngx-skeleton-loader>
        </div>
      </ng-template>
    
    </ng-select>
    </div>
    
