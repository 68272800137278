import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { TenantCustomerService } from 'app/modules/tenant-customers/services/tenant-customer.service';
import { SubSink } from 'subsink';
import { CustomerSelectComponent } from 'app/shared/components/customer-select/customer-select.component';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { CommonService } from 'app/core/services/common.service';
import { AppEventType } from 'app/core/enums/common.enum';
import { CallCenterSharedService } from '../../services/call-center-shared.service';
import { DataFilterRequest } from 'app/core/models/grid-filter.models';
import { CompanyUser } from 'app/modules/tenant-customers/models/tenant-customer';

@Component({
  selector: 'customer-contact-add',
  standalone: true,
  imports: [CommonModule,MatFormFieldModule,ReactiveFormsModule,
            MatInputModule,CustomerSelectComponent],
  templateUrl: './customer-contact-add.component.html',
  styleUrl: './customer-contact-add.component.scss',
  animations: [
    trigger('fadeInOut', [
      state('in', style({
        opacity: 1,
        transform: 'translateY(0)'
      })),
      transition('void => *', [
        style({
          opacity: 0,
          transform: 'translateY(-20px)'
        }),
        animate('0.5s ease-out')
      ]),
      transition('* => void', [
        animate('0.5s ease-in', style({
          opacity: 0,
          transform: 'translateY(-20px)'
        }))
      ])
    ])
  ]
})
export class CustomerContactAddComponent implements OnInit, OnDestroy{

  public submitted: boolean = false

  public itemForm: FormGroup;

  @Output() close: EventEmitter<boolean> = new EventEmitter();

  @Output() onSave: EventEmitter<any> = new EventEmitter();

  private subs: SubSink = new SubSink();

  // private idVal: string;
  // @Input() set id (val: string) {
  //   if(val)
  //   {
  //     this.idVal = val;
  //   }
  // }

  @Input() id:number
  
  public showAdditional_phone:boolean = false

  public showAdditional_mail:boolean = false 

  @Input() contactDetails:any

  @Input() phone:string
  constructor(private _fb:FormBuilder, private _tenantCustomerService: TenantCustomerService,
    private _toaster: ToastrService,_route:ActivatedRoute,private _commonService: CommonService,
    private _callcenterShared:CallCenterSharedService
  ) {
      //this.id = _route.snapshot.paramMap.get('id');
  }

  ngOnInit(): void {

    console.log('temp_data',this._callcenterShared.contactSaveTempData)
    const initialFormData = this._callcenterShared.contactSaveTempData

    this.itemForm = this._fb.group({
      id:[initialFormData?.id || null],
      company:[initialFormData?.company_id || '',[Validators.required]],//tenentid
      suborg:[initialFormData?.suborg_id || this._tenantCustomerService.suborgId ],
      tenant:[initialFormData?.tenant_id || this._tenantCustomerService.tenantId],
      first_name:[initialFormData?.first_name || '',[Validators.required]],
      last_name:[initialFormData?.last_name||null],
      phone:[initialFormData?.phone||this.phone,Validators.pattern(/^\(?([0-9]{3})\)?[-]?([0-9]{3})[-]?([0-9]{2})$/)], 
      email:[initialFormData?.email||null,[Validators.email]],
      mobile:[initialFormData?.mobile||null,Validators.pattern(/^\(?([0-9]{3})\)?[-]?([0-9]{3})[-]?([0-9]{2})$/)],
      url:[initialFormData?.url||null,Validators.pattern(/^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/)],
      secondary_email:[initialFormData?.secondary_email||null],
      company_details:[initialFormData?.company_details||'']
      })
      this.subscribeEvents()
      this.itemForm.valueChanges.subscribe(value => {
        if(this.itemForm.controls['id'].value == null)
        {
          this._callcenterShared.contactSaveTempData = value
        }
      });

      if(this.id)
      {
        this.getContactInfoById(this.id)
      }    

      this._tenantCustomerService.storeData$.subscribe(res => {
        console.log('res_contact',res.data.result[0])
        this.itemForm.patchValue(res.data.result[0])  
      })

      if(this.contactDetails)
      {
        this.itemForm.patchValue(this.contactDetails)
      }
  }
 
  subscribeEvents() {
    this.subs.sink = this._commonService.on(AppEventType.SuborgSwitch).subscribe(event => {
      console.log('suborg switch', event)
      this.itemForm.controls['suborg'].setValue(this._tenantCustomerService.suborgId)
    });
  }

  closeContact()
  {
    this._callcenterShared.contactSaveTempData = null;
    this.close.emit(true);
  }

  submit() {

    this.submitted = true;
    console.log('isvalid',this.itemForm.valid)
    console.log('formval',this.itemForm.value)

    if(!this.itemForm.valid){
      return;
    }

    if(this.itemForm.controls['suborg'].value == -1){

      this._toaster.warning("Please select SubOrg before saving");
      return
    }

    this.setNullValuesIfEmpty();

    let api:any = this.id ? this._tenantCustomerService.updateCustomerContact(this.id.toString(), this.itemForm.getRawValue())
        : this._tenantCustomerService.createCustomerContact(this.itemForm.getRawValue());

    this.subs.sink = api.subscribe(
        resp => {
          if(resp.http_status == 200)
          {
            if(!this.id)
            {
              //this.resetForm()
            }

           let msg = this.id ? `Customer contact info updated` : `New customer contact created`;
           this._toaster.success(msg);
           this._callcenterShared.contactSaveTempData = null;
           this.onSave.emit(resp.data.result);
          }
          else
          {
            this._toaster.error(resp.message[0]);
          } 
          this.submitted = false

        },
        error => {
        this.submitted = false
          this._toaster.error("Error on save data")
        }
    )
  }

  ngOnDestroy(): void {
      this.subs.unsubscribe();
  }

  getContactInfoById(id:number){

    let request: DataFilterRequest = {

      filters: [{displayName: "id", colname: "id", condition: "equalto", value: id.toString(), operator: "AND"}],
      sort: [],
      base_filters: []
    };
    this.subs.sink = this._tenantCustomerService.fetchCustomeContactsFiltered_LocalDB(request)
    // .subscribe(resp => { 
    //   if (resp.http_status == 200) {
    //     console.log('contactinfo',resp.data.result)
    //     this.itemForm.patchValue(resp.data.result);
    //     this.itemForm.controls['tenant_customer'].setValue(resp.data.result.tenant_customer)
    //   }
    // })

  }

  setCustomer(item:any)
  {
    this.itemForm.controls['company'].setValue(item[0]._id)
    this.itemForm.controls['company_details'].setValue([{_id:item[0]._id,name:item[0].name}])
  }

  toggleAdditionalPhone()
  { 
    this.showAdditional_phone = !this.showAdditional_phone
  }

  toggleadditionalMail()
  {
    this.showAdditional_mail = !this.showAdditional_mail
  }

  setNullValuesIfEmpty() {
    Object.keys(this.itemForm.controls).forEach(key => {
      if (this.itemForm.get(key).value === '') {
        this.itemForm.get(key).setValue(null);
      }
    });
  }
 

}
