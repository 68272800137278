import { ResourceConstants } from '../constants/resources.constants';
import { id } from '../utils/global.functions';
import { WHBaseModel } from './common.models';

export interface GFColumn {

  displayName: string;

  columnName: string;

  columnType: GFColumnTypes;

  resource?: ResourceConstants;

  gridColumn?: string;

  options?: { displayName: string, value: any }[];

  optionValueType?: GFColumnTypes;

  dependentColumn?:string;

  dependentValue?:any;
}

export interface GFCondition {

  displayName: string;

  condition: string;

  allowedTypes: string[];
}

export enum GFColumnTypes {
  string = 'string',
  boolean = 'boolean',
  number = 'number',
  json = 'json',
  ref = "ref",
  date = "date",
  select = 'select',
  phone = 'phone',
  date_range = 'date_range'
}

export class GFilterRow {

  id: string = id();

  column: GFColumn;

  condition: GFCondition;

  operator: string;

  value: any;

  allowedConditions: GFCondition[];

  options?: { displayName: string, value: any }[];

  dependentValue?:any;
}

export class GFilterGroup{

  public conditions?:GFilterParam[];

  public operator?:string;
  
}


export class GFilterResponse{

  public base_filters?:GFilterParam[]=[];

  public filters:GFilterParam[]=[];

  public offset?:number=0;

  public limit?:number=50;
}

export class GFilterGroupResponse{

  public filters:GFilterGroup[]=[];

  public offset:number=0;

  public limit:number=50;
}

export class GFilterParam {

  public displayName?: string;

  public colname?: string;

  public condition?: string;

  public operator?: string;

  public value?: any;

  public resource?: ResourceConstants;

  public gridColumn?: string;

  public children?: GFilterParam[];

  public custom?: boolean;

  public customDataId?: number;

  public type?: GFColumnTypes;

  public default?:boolean;

  public conditions?: any;
}

export class GSortParam {

  public colname: string;

  public direction: string;
}

export class DataFilter extends WHBaseModel{

  public id: number;

  public name: string;

  public condition: any;

  public module: number;

  public default:boolean;
}

export interface DataFilterRequest {

  parent_filters?: GFilterParam[];

  base_filters?: GFilterParam[];

  filters: GFilterParam[];

  sort: GSortParam[];
}

export interface DataFilterGroupRequest {

  base_filters?: GFilterParam[];

  filters: GFilterGroup[];

  sort: GSortParam[];
}

export interface DataFilterResponse<T> {

  message: string[];

  data: {

    result: T;

    count: number;

    total_count? : number;

  };

  http_status: number;
}

export interface DataResponse<T> {

  message: string[];

  data: any;

  http_status: number;
}

export interface IPagingView {

  page: Pagination;

  sort: GSortParam[];

  sortLookup: any;
}

export interface Pagination {

  count: number;

  limit: number;

  offset: number;

  pageNumber: string;
}

export class GridTab{

  name:string;

  id:string;

  selected:boolean;

  icon?:string;

  iconWidth?:number;

  iconHeight?:number;

  textColor?:string='';

  textHoverColor?:string='';

}

export class TabFilter{

  name:string;

  filter:GFilterParam[];

  selected?:boolean;

}

export class PageObject {

  length: number=0;

  pageIndex: number=0;

  pageSize: number=10;

  previousPageIndex: number=0;

  showTotalPages?:number=6;

  pageSizes?:number[]=[10, 30, 50, 100];
}
export class SwitchTab{

  name:string;

  id:string;

  selected:boolean;
}

export class TbFilterMetaModel {
 
  public storeName: string = ""

  public totalCount: number = 0

  public lastUpdate:Date = null
}

export class NewbtnOptionModel {

  public name: string;

  public imgSrc: string;
}