import { FuseNavigationItem } from "@fuse/components/navigation";


export const NavRoutes:FuseNavigationItem[]=[
    {
        id   : 'organization',
        title: 'Organization',
        type : 'collapsable',
        // icon : 'heroicons_outline:building-office-2',
        iconImg:'building.svg',
        children:[
            {
                id   : 'users',
                title: 'Users',
                type : 'basic',
                icon : 'heroicons_outline:user',
                link : '/org/users'
            },
            {
                id   : 'usergroups',
                title: 'User Groups',
                type : 'basic',
                icon : 'heroicons_outline:users',
                link : '/org/usergroups'
            }
        ]
    }, 
    {
        id   : 'phone-system',
        title: '    ystem',
        type : 'collapsable',
        // icon : 'heroicons_outline:phone',
        iconImg:'headphone.svg',
        children:[
            {
                id   : 'buy-numbers',
                title: 'Buy Numbers',
                type : 'basic',
                // icon : 'heroicons_outline:phone',
                iconImg:'call-outgoing.svg',
                link : '/phone-system/did-purchase'
            },
            {
                id   : 'phone-numbers',
                title: 'Phone Numbers',
                type : 'basic',
                // icon : 'heroicons_outline:phone',
                iconImg:'call-outgoing.svg',
                link : '/phone-system/dids'
            },
            {
                id   : 'incoming-routes',
                title: 'Incoming Routes',
                type : 'basic',
                // icon : 'heroicons_outline:phone-arrow-down-left',
                iconImg:'call-incoming.svg',
                link : '/phone-system/incoming-routes'
            },
            {
                id   : 'call-history',
                title: 'Call History',
                type : 'basic',
                // icon : 'heroicons_outline:user-group',
                iconImg:'graph.svg',
                link : '/phone-system/call/history'
            },
            // {
            //     id   : 'blocklist',
            //     title: 'Block List',
            //     type : 'basic',
            //     icon : 'heroicons_outline:phone-x-mark',
            //     link : '/phone-system/block-list'
            // }
        ]
    }, 
    {
        id   : 'companies',
        title: 'Companies',
        type : 'collapsable',
        // icon : 'heroicons_outline:users',
        iconImg:'users.svg',
        children:[
            {
                id   : 'companies-list',
                title: 'companies list',
                type : 'basic',
                icon : 'heroicons_outline:users',
                link : '/companies'
            }
        ]
    }, 
    {
        id   : 'omni-chat',
        title: 'Omni Chat',
        type : 'basic',
        // icon : 'heroicons_outline:chat-bubble-left-right',
        iconImg:'chat.svg',
        link : '/omni-chat'
    }
]