import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonService } from 'app/core/services/common.service';
import { SubSink } from 'subsink';
import { AppEventType, CommonPanelEvents } from 'app/core/enums/common.enum';
import { CompanyUser } from 'app/modules/tenant-customers/models/companies.models';
import { CompanyUserViewComponent } from 'app/modules/tenant-customers/components/company-user-view/company-user-view.component';
import { CompanyUserAddComponent } from 'app/modules/tenant-customers/components/company-user-add/company-user-add.component';

@Component({
  selector: 'common-right-panel',
  standalone: true,
  imports: [CommonModule,CompanyUserAddComponent,CompanyUserViewComponent],
  templateUrl: './common-right-panel.component.html',
  styleUrl: './common-right-panel.component.scss'
})
export class CommonRightPanelComponent {

  public opened: boolean

  public subs: SubSink = new SubSink();

  public type: string

  // public contactSaveTempData : CompanyUser = null;

  public id:any;

  public phone:string = ""

 // public viewContactAfterSave: boolean = false


  constructor(private _commonService:CommonService){

  }

  ngOnInit(): void {

    this.subs.sink = this._commonService.on(AppEventType.CommonPanelEvent).subscribe(event => {

      this.id = null
      if(event.payload.EventName == CommonPanelEvents.OpenContact){
        this.opened = true
        this.type = 'contact' 
        if(event.payload.AdditionalInfo){
          if(event.payload.AdditionalInfo?.id){
            this.id = event.payload.AdditionalInfo.id
          }
          if(event.payload.AdditionalInfo?.users_number){
            this.phone = event.payload.AdditionalInfo.users_number
          }
        }

      }
      else if(event.payload.EventName == CommonPanelEvents.OpenContactView){
        this.opened = true
        this.type = 'contact_view'
        if(event.payload.AdditionalInfo){
          if(event.payload?.AdditionalInfo.id){
            this.id = event.payload.AdditionalInfo.id
          }
          if(event.payload?.AdditionalInfo.users_number){
            this.phone = event.payload.AdditionalInfo.users_number
          }
        }
      }
      else if(event.payload.EventName == CommonPanelEvents.ClosePanel){

        this.closePanel()
      }
      

  });


  }

  closePanel(){
     this.opened = false
     this.type = ''
  }
  onSave(type:string,data?:any){
    if(type == 'contact'){
      this._commonService.dispatch({type:AppEventType.CommonPanelEvent,payload:{EventName:CommonPanelEvents.SaveContact,AdditionalInfo:data}})
      this.closePanel();
      // if(this.viewContactAfterSave)
      //   {
      //     this._commonService.dispatch({type:AppEventType.CommonPanelEvent,payload:{EventName:CommonPanelEvents.OpenContactView,AdditionalInfo:data}})
      //   }
      // else{
      //   this.closePanel()
      // }
    }
  
    
  }

  onDeleteContact(event){
    this._commonService.dispatch({type:AppEventType.CommonPanelEvent,payload:{EventName:CommonPanelEvents.DeleteContact,AdditionalInfo:event}})
  }
}
