<div class="bg-white">
    <!-- Header with Close Icon -->
    <div class="flex justify-between items-center mb-4 mt-2">
        <h2 class="text-lg font-semibold">Add Company User</h2>
        <button class="text-gray-500 hover:text-gray-700" (click)="closeContact()">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
        </button>
    </div>
    <!-- Grid Container for Inputs -->
    <form action="" [formGroup]="itemForm">

        <div class="grid grid-cols-2 gap-x-4">
            <div class="col-span-2 mb-4">
                <customer-select (valueChange)="setCustomer($event)" [value]="itemForm.controls['company_details'].value"></customer-select>
            </div>
            <mat-form-field class="w-full">
                <mat-label class="text-base font-medium tracking-[-0.32px]">First Name
                </mat-label>
                <input matInput min="0" max="100" formControlName="first_name" placeholder="Enter name">
                <mat-error *ngIf="itemForm.controls['first_name'].hasError('required') && submitted">
                    This field is required.
                </mat-error>
            </mat-form-field>

            <mat-form-field class="w-full">
                <mat-label class="text-base font-medium tracking-[-0.32px]">Last Name
                </mat-label>
                <input matInput min="0" max="100" formControlName="last_name" placeholder="Enter last name">
            </mat-form-field>

            <div class="div">
                <mat-form-field class="w-full">
                    <mat-label class="text-base font-medium tracking-[-0.32px]">Phone
                    </mat-label>
                    <input matInput min="0" max="100" formControlName="phone" placeholder="Enter phone">
                    <mat-error *ngIf="itemForm.controls['phone'].hasError('pattern') && submitted">
                        Please enter a valid phone number.
                    </mat-error>
                </mat-form-field>
                <div class="w-full" *ngIf="showAdditional_phone"  [@fadeInOut]>
                    <mat-form-field class="w-full">
                        <mat-label class="text-base font-medium tracking-[-0.32px]">Phone
                        </mat-label>
                        <input matInput min="0" max="100" formControlName="mobile" placeholder="Enter phone">
                        <mat-error *ngIf="itemForm.controls['mobile'].hasError('pattern') && submitted">
                            Please enter a valid phone number.
                        </mat-error>
                    </mat-form-field>
                </div>

                <span class="ml-auto text-[16px] text-[#246AD4] font-medium cursor-pointer underline"
                (click)="toggleAdditionalPhone()">
                {{!showAdditional_phone?'+another phone':'-remove phone'}}</span>
            </div>
           
            <div class="div">
                <mat-form-field class="w-full">
                    <mat-label class="text-base font-medium tracking-[-0.32px]">Email
                    </mat-label>
                    <input matInput min="0" max="100" formControlName="email" placeholder="Enter email">
                    <mat-error *ngIf="itemForm.controls['email'].hasError('email') && submitted">
                        Please enter a valid email.
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="w-full" *ngIf="showAdditional_mail" [@fadeInOut]>
                    <mat-label class="text-base font-medium tracking-[-0.32px]">Email
                    </mat-label>
                    <input matInput min="0" max="100" formControlName="secondary_email" placeholder="Enter email">
                    <mat-error *ngIf="itemForm.controls['secondary_email'].hasError('email') && submitted">
                        Please enter a valid email.
                    </mat-error>
                </mat-form-field>
                <span class="ml-auto text-[16px] text-[#246AD4] font-medium cursor-pointer underline"
                (click)="toggleadditionalMail()">
                    {{!showAdditional_mail?'+another mail':'-remove mail'}}</span>
            </div>
            <!-- <mat-form-field class="w-full">
                <mat-label class="text-base font-medium tracking-[-0.32px]">Company
                </mat-label>
                <input matInput min="0" max="100" formControlName="company_name" placeholder="Enter company">
                <mat-error *ngIf="itemForm.controls['company_name'].hasError('required') && submitted">
                    This field is required.
                </mat-error>
            </mat-form-field> -->

            <mat-form-field class="w-full">
                <mat-label class="text-base font-medium tracking-[-0.32px]">URL
                </mat-label>
                <input matInput min="0" max="100" formControlName="url" placeholder="Enter URL">
                <mat-error *ngIf="itemForm.controls['url'].hasError('pattern') && submitted">
                    Please enter a valid URL.
                </mat-error>
            </mat-form-field>


        </div>
        <div class="flex justify-center m-4 gap-3">
            <button
                class="dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 px-4 py-2 mr-2 font-semibold text-base rounded-lg cancel-btn transform transition-transform active:scale-95"
                (click)="closeContact()">Cancel</button>
            <button
                class="text-white bg-[#7743DB] focus:outline-none dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 px-4 py-2 rounded-md font-semibold text-base transform transition-transform active:scale-95"
                (click)="submit()">Save</button>
        </div>
    </form>
</div>