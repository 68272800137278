
export enum AppConstants {
    AUTH_TOKEN = "simplyfuse.cloud.token",
    USER_ID = "simplyfuse.cloud.uid",
    TENANT_ID = "simplyfuse.cloud.tenantid",
    USER_OBJ = "simplyfuse.cloud.uo",
    USER_TYPE = "simplyfuse.cloud.ut",
    APP_RESOURCES = "simplyfuse.cloud.resources",
    APP_PERMISSIONS = "simplyfuse.cloud.permissions",
    USER_PREVILEGES = "simplyfuse.cloud.user.previleges",
    USER_AUTH = "simplyfuse.cloud.user.auth",
    USER_GRIDS = "simplyfuse.cloud.user.grids",
    USER_SUBORGS = "simplyfuse.cloud.user.suborgs",
    CURRENT_SUBORG = "simplyfuse.cloud.user.suborgs.current",
    USER_EMAIL = "simplyfuse.cloud.user.auth.email",
    USER_ROLE = "simplyfuse.cloud.user.auth.role",
    TAB_WIDTH = "simplyfuse.cloud.tabwidth",
    DEVICE_ID = "simplyfuse.cloud.deviceid",
    SIP_URI = "simplyfuse.cloud.sipuri",
    APP_VERSION = "simplyfuse.cloud.appversion",
    LATEST_VERSION_CHECKED_TIME = "simplyfuse.cloud.latestversioncheckedtime",
}
