<div *ngIf="data" class="toolTip-container" (mouseenter)="onMouseEnter()" (mouseleave)="onMouseLeave()">
  <div class="tooltip-content">
      <!-- <img [src]="data.image" alt="{{ data.name }}" class="tooltip-image"> -->
      <div class="flex-0 items-center justify-center rounded-full w-[74px] h-[74px] border p-[3px]">
        <ng-container *ngIf="data.image">
          <img
              class="w-full h-full rounded-full object-cover"
              [src]="data.image"
              alt="Contact avatar"/>
      </ng-container>
        <ng-container *ngIf="!data.image">
          <div class="flex items-center justify-center w-full h-full rounded-full uppercase text-lg
          bg-gray-200">
              @if(data.name.trim()){
              {{data.name | shortName}}
              }@else {
              UN
              }
          </div>
        </ng-container>
      </div>
      <div class="flex flex-col w-full">
        <div class="flex justify-between">
          <div class="text-[22px] tracking-[-0.32px] leading-[30px] font-semibold truncate w-[208px]">{{ data.name.trim() || 'Unknown' }}</div>
          <button *ngIf="data.number" InitiateCall class="call-btn w-[35px] h-[35px] bg-[#0ABC7C] rounded-full flex items-center justify-center mx-2"
                  [callType]="'contact'" [toNumber]="data.number" [agentsipuri]="agentSipUri">
              <img class="w-6 h-6" alt="call" src="../../../../../assets/icons/call-center/call-pick.svg">
          </button>
        </div>
        <p *ngIf="data.number" class="text-base font-normal tracking-[-0.32px] leading-[28px] flex"><i class="icon-call text-[20px] flex items-center mr-1"></i>{{ data.number | phoneNumber}}</p>
        <div *ngIf="data.email" class="flex">
          <i class="icon-sms text-[20px] flex items-center mr-1 text-[#384244]"></i>
          <p class="text-[#1065B4] text-base font-normal tracking-[-0.32px] leading-[28px] truncate w-[238px]">{{data.email}}</p>
        </div>
      </div>
  </div>
  <div class="flex justify-center gap-3 mb-2.5">
    <button *ngIf="showMoreInfo"  class="footer-button text-sm font-semibold tracking-[-0.32px] leading-[20px]" (click)="onAction('info')"
    ShowContactView [contactid]="data.id">
      <i class="icon-profile-circle flex items-center text-[20px] mr-1"></i>
      More Info
    </button>
    <button *ngIf="data.number && !_commonService.isSip(data.number)" InitiateChat class="footer-button text-sm font-semibold tracking-[-0.32px] leading-[20px]" (click)="onAction('chat')"
    [toNumber]="data.number">
      <i class="icon-messages-2 flex items-center text-[20px] mr-1"></i>
      Message
    </button>
  </div>
</div>