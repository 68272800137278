<div class="div"   *transloco="let t;scope:'callcenter'">
    <div
    *ngIf="!showKeypad">
     <div *ngIf="callerSession" [@fadeInOut] class="fade-in-out-div" >
     <section id="ongoing-call">
         <div class="flex mb-2">
            <div class="timer">
             <span class="text-sm font-medium">{{formatTime(callerSession.customValues?.elapsedTime)}}</span>
            </div>
             <label for="" class="font-semibold text-base ml-2" *ngIf="callerSession.session?.state != 'Terminated'">{{t('callcenter.OngoingCall')}}</label>
         </div>
         <div class="ongoing-container">
             <div class="flex items-center pr-1 pl-1">
                 <div class="h-8 w-8 rounded-full mr-1 p-1 flex items-center justify-center text-lg bg-white border-black text-[#0E2445]">
                    @if(callerSession?.customValues?.callType == 'inbound'){

                        @if(callerSession?.customValues?.isAgentCall)
                        {
                            @if(isNullorEmpty(callerSession?.callDetails?.agent_name)){
                                UN
                            }
                            @else{
                                {{callerSession.callDetails?.agent_name | shortName}}
                            }
                        }
                        @if(!callerSession?.customValues?.isAgentCall)
                        {
                            @if(isNullorEmpty(callerSession.callDetails?.company_users_name)){
                                UN
                            }
                            @else{
                                {{callerSession.callDetails?.company_users_name | shortName}}
                            }
                        } 
                    }
                    @else if(callerSession?.customValues?.callType == 'outbound'){
                        @if(callerSession?.customValues?.isAgentCall)
                        {
                            @if(isNullorEmpty(callerSession.callDetails?.to_sip_user?.name)){
                                UN
                            }
                            @else{
                                {{callerSession.callDetails?.to_sip_user?.name | shortName}}
                            }
                        }
                        @if(!callerSession?.customValues?.isAgentCall)
                        {
                            @if(isNullorEmpty(callerSession.callDetails?.users_name)){
                                UN
                            }
                            @else{
                                {{callerSession.callDetails?.users_name | shortName}}
                            }
                        }
                       
                    }
                 </div>
                 @if(callerSession?.customValues?.isAgentCall)
                 {
                    <p class="pl-2 font-semibold text-base" *ngIf="callerSession?.customValues?.callType == 'inbound'">
                        {{isNullorEmpty(callerSession?.callDetails?.agent_name)? 'Unknown': 
                        callerSession?.callDetails?.agent_name+'('+callerSession?.callDetails?.agent_sub_org_name+')'}}</p>

                    <p class="pl-2 font-semibold text-base" *ngIf="callerSession?.customValues?.callType == 'outbound'">
                        {{isNullorEmpty(callerSession.callDetails?.to_sip_user?.name)? 'Unknown': 
                        callerSession.callDetails?.to_sip_user?.name+'('+callerSession?.callDetails?.to_sip_user?.suborg__name+')'}}</p>
                 }
                 @if(!callerSession?.customValues?.isAgentCall)
                 {
                    <p class="pl-2 font-semibold text-base" *ngIf="callerSession?.customValues?.callType == 'inbound'">
                        {{isNullorEmpty(callerSession.callDetails?.company_users_name)? 'Unknown': 
                        callerSession.callDetails?.company_users_name}}</p>
                    <p class="pl-2 font-semibold text-base" *ngIf="callerSession?.customValues?.callType == 'outbound'">
                        {{isNullorEmpty(callerSession.callDetails?.users_name)? 'Unknown': 
                        callerSession.callDetails?.users_name}}</p>
                 }
               
             </div>
             <div class="flex items-center pr-1 pl-1">
                 <label class="phone-number font-semibold">
                    @if(callerSession?.customValues?.callType == 'inbound'){
                        @if(callerSession.customValues.isAgentCall){
                            {{callerSession?.callDetails?.from_number.split('@')[0]}}
                         }
                         @else{
                            {{callerSession?.callDetails?.from_number?.length > 6 ? (callerSession?.callDetails?.from_number | phoneNumber) : callerSession?.callDetails?.from_number}}
                         }
                    }
                    @else{
                        {{callerSession?.callDetails.users_number  | phoneNumber}}
                    }
                </label>
                
                <!-- [ngClass]="callerSession.session?.state == 'Established' || callerSession?.customValues.isCallOnHold? 'call-end-active' : 'call-end-disabled'" -->
                 <img class="icon-call-end ml-auto call-end-active"
                     src="../../../../../assets/icons/call-center/call-end.svg" alt="Mute"
                     (click)="onEndCall()">
             </div>

         </div>
     </section>
     <section id="actions">
         <div class="action-container text-sm font-medium text-blue-900 mt-1">
             <div class="flex justify-between rounded-lg">
                 <div class="action  flex flex-col justify-center items-center action-box" 
                 [ngClass]="{'action-active':this.callerSession.customValues.isMuted}">
                     <img class="flex action-ico" src="../../../../../assets/icons/call-center/mute.svg" alt="Mute" (click)="onMuteCall()" 
                     [ngClass]="{'action-disable': callerSession.session?.state != 'Established'}">
                        <p class=""
                        for="">{{t('callcenter.Mute')}}</p>
                 </div>
                 <!-- <div class="action flex flex-col justify-center items-center action-box" (click)="onHoldCall()" 
                 [ngClass]="{'action-active':this.callerSession.customValues.isCallOnHold}"
                 [ngClass]="{'action-disable': this.callerSession?.callDetails?.parent_call_id == null}">
                     <img class="flex action-ico" src="../../../../../assets/icons/call-center/pause.svg" alt="Hold">
                        <p class=""
                        for="" [ngClass]="{'text-gray-300': this.callerSession?.callDetails?.parent_call_id == null}">{{t('callcenter.Hold')}}</p>
                 </div> -->
                 <div class="action flex flex-col justify-center items-center action-box" 
                 [ngClass]="{'action-active':this.callerSession.customValues.isCallOnHold}">
                     <img class="flex action-ico" src="../../../../../assets/icons/call-center/pause.svg" alt="Hold" (click)="onHoldCall()" 
                     [ngClass]="{'action-disable': !callerSession.customValues.call_answered || callerSession.session?.state != 'Established'}">
                        <p class=""
                        for="">{{t('callcenter.Hold')}}</p>
                 </div>
                 <div class="action flex flex-col justify-center items-center action-box" (click)="transfer()">
                     <img class="flex action-ico" src="../../../../../assets/icons/call-center/transfer.svg" alt="Transfer" 
                     [ngClass]="{'action-disable': !callerSession.customValues.call_answered || callerSession.session?.state != 'Established'}">
                        <p class="" for="">{{t('callcenter.Transfer')}}</p>
                 </div>
                 <div class="action flex flex-col justify-center items-center action-box" (click)="record()">
                     <img class="flex action-ico" src="../../../../../assets/icons/call-center/record.svg" alt="Record" 
                     [ngClass]="{'action-disable': !callerSession.customValues.call_answered || callerSession.session?.state != 'Established'}">
                        <p class="" for="">{{t('callcenter.Record')}}</p>
                 </div>
                 <div class="action flex flex-col justify-center items-center action-box"  (click)="onShowKeypad()">
                     <img class="flex dial-ico mt-2" src="../../../../../assets/icons/call-center/dialer.svg" alt="Key Pad" 
                     [ngClass]="{'action-disable': !callerSession.customValues.call_answered || callerSession.session?.state != 'Established'}">
                        <p class=""
                        for="">{{t('callcenter.Dialpad')}}</p>
                 </div>
             </div>
         </div>
     </section>
     <ng-container *ngIf="false">
         <div class="flex mt-3 justify-center">
             <button type="button" class="py-1 px-2 me-2 text-sm font-medium focus:outline-none rounded
              border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4
               focus:ring-gray-100 dark:focus:ring-gray-700 dark:text-gray-400 " (click)="showMoreInfo()">{{t('callcenter.MoreInfo')}}</button>
             <button type="button" class="py-1 px-2 me-2 text-sm font-medium focus:outline-none rounded
              border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4
               focus:ring-gray-100 dark:focus:ring-gray-700 dark:text-gray-400 " (click)="addContact()">{{t('callcenter.AddContact')}}</button>
         </div>
     </ng-container>


 </div>
</div>
<ng-container *ngIf="showKeypad">
<key-pad (closeKeypad)="onCloseKeypad()"
[session] = "callerSession"
></key-pad>
</ng-container>
</div>

