import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { sidePanelConstants } from 'app/core/constants/app-shared.constants';
import { AppEventType, CommonPanelEvents } from 'app/core/enums/common.enum';
import { CommonService } from 'app/core/services/common.service';

@Directive({
  selector: '[ShowContactView]',
  standalone: true
})
export class ShowContactViewDirective {

  @Input() contactid: any

  constructor(private el: ElementRef,private commonService:CommonService) { }

  @HostListener('click')

  onClick() {
    this.openContactView()
  }

  openContactView(){
    const sidePanelConstants:sidePanelConstants ={EventName:CommonPanelEvents.OpenContactView}  
    sidePanelConstants.AdditionalInfo = {id: this.contactid}
    this.commonService.dispatch({type:AppEventType.CommonPanelEvent,payload:sidePanelConstants});
  }
  
}
